import React, { Component, ReactChildren } from 'react';
import { translate } from 'react-i18next';

// Utilities
import kebabCase from 'lodash/kebabCase';

// Components
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import BreadCrumbs from '../../components/BreadCrumbs';
import TagLayout from '../../layouts/TagLayout';
import { Consumer as TagConsumer } from '../../context/tag';
import { i18n } from 'i18next';

interface Tag {
  fieldValue: string,
  totalCount: number,
}

interface IProps {
  i18n: i18n,
  data: {
    allMarkdownRemark: {
      group: Array<Tag>,
    }
  },
  site: {
    siteMetadata: {
      title: string,
    }
  }
}

class TagsPage extends Component<IProps> {
  render() {
    const { i18n } = this.props;

    return (
      <TagLayout>
        <div>
          <div className="main-wrapper">
            <Helmet title="Mijn Presta | Blog"/>
            <Header/>
            <BreadCrumbs breadcrumbs={[
              {
                text: 'Home',
                href: {
                  nl: '',
                  en: '',
                }
              },
              {
                text: 'Blog',
                href: {
                  nl: 'blog/',
                  en: 'blog/',
                }
              },
              {
                text: 'Tags',
              }
            ]}/>
            <section id="main" className="wrapper">
              <div className="inner">
                <h1 className="major">Tags</h1>
                <ul>
                  <TagConsumer>
                    {({ group }: any) => group.map((tag: Tag) => (
                      <li key={tag.fieldValue}>
                        <Link to={`/${i18n.language}/tags/${kebabCase(tag.fieldValue)}/`}>
                          {tag.fieldValue} ({tag.totalCount})
                        </Link>
                      </li>
                    ))}
                  </TagConsumer>
                </ul>
              </div>
            </section>
            <Footer/>
          </div>
        </div>
      </TagLayout>
    );
  }
}

export default translate()(TagsPage);
