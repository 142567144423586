import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { translate } from 'react-i18next';
import _ from 'lodash';

import { Provider as TagProvider } from '../context/tag';

import '../assets/sass/main.scss';
import '../components/i18n';

import { I18nextProvider } from 'react-i18next';
import { i18n } from 'i18next';

interface IProps {
  children?: JSX.Element,
  data?: any,
  i18n?: i18n,
}

const TagLayout = ({ children, data, i18n }: IProps) => {
  let canonical: string;
  if (typeof window !== 'undefined') {
    canonical = _.get(window, 'location.pathname');
    if (window.location.pathname.substring(0, 4) !== `/${i18n.language}/`) {
      canonical = `/${i18n.language}/${canonical}`;
    }
    if (canonical.slice(-1) !== '/') {
      canonical += '/';
    }
  }

  return (
    <StaticQuery query={graphql`
query TagsQuery {
  site {
    siteMetadata {
      title
    }
  }
  en: allMarkdownRemark(
    limit: 2000
    filter: { frontmatter: {lang: { eq: "en" } } }
  ) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
    }
  }
  nl: allMarkdownRemark(
    limit: 2000
    filter: { frontmatter: {lang: { eq: "nl" } } }
  ) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
    }
  }
}
    `}
     render={data => (
       <TagProvider value={{
         group: data[i18n.language].group,
       }}>
         <Helmet
           title="Tags"
           meta={[
             { name: 'description', content: 'Mijn Presta' },
           ]}
         >
           <link rel="canonical" href={canonical}/>
         </Helmet>
         <I18nextProvider i18n={i18n}>
           <div>
             {children}
           </div>
         </I18nextProvider>
       </TagProvider>
     )}
    />
  );
};

export default translate()(TagLayout);
